<template>
  <b-container class="full-height">
    <b-row
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <h1 style="font-size:128px;">404</h1>
      <p>Oops, you've stumbled on the wrong page. Please try again</p>
    </b-row>
  </b-container>
</template>

<script>
export default {}
</script>

<style>
.full-height {
  height: calc(100vh - 56px);
}
</style>
